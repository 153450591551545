export default {
  "CheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in"])},
  "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
  "Search for address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for address"])},
  "When": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When"])},
  "aboutTheExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More information"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
  "access": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
    "byCar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By car"])},
    "byPublicTransport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By public transport"])},
    "byTourBus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By tour bus"])},
    "isHardSurfacedRoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hard-surfaced road"])},
    "isSignPosted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guideposts or road signs"])},
    "onFoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On foot"])}
  },
  "activation": {
    "activateUserError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User activation failed"])},
    "activateUserSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User succesfully activated"])},
    "invalidActivationCodeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid activation code"])}
  },
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "addAnotherDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add discount"])},
  "addAnotherHousingUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add housing unit"])},
  "addAnotherPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another period"])},
  "addAnotherPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another price"])},
  "addAnotherUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another user"])},
  "addAttachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add attachments"])},
  "addBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add business"])},
  "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new"])},
  "addNewBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new business"])},
  "addNewClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new client"])},
  "addNewResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new resource"])},
  "addNewTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new task"])},
  "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
  "addToCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully added to cart"])},
  "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added"])},
  "addedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added date"])},
  "additionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional information"])},
  "additionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional services / products"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "address_": {
    "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
    "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["County"])},
    "farmApartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farm/apartment"])},
    "villageSuburbTown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Village/suburb/town"])}
  },
  "admin": {
    "companyListPage": {
      "filters": {
        "showTestCompanies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show test service providers"])}
      },
      "headers": {
        "isTestCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test service providers"])},
        "lastUserLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last login"])},
        "publishedServicesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published sales articles"])},
        "servicesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales articles"])},
        "usersCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])}
      },
      "updateIsTestCompanyErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Service provider \"", _interpolate(_named("company")), "\" test status update unsuccessful:"])},
      "updateIsTestCompanySuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Service provider \"", _interpolate(_named("company")), "\" test status updated successfully."])}
    },
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "postponedRefundsPage": {
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
      "retryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
      "retryRefundErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry refund was unsuccessful"])},
      "retryRefundSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry refund was successful"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postponed refunds"])}
    },
    "tabs": {
      "commissionReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission report"])},
      "depositReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit report"])},
      "providerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service providers"])}
    }
  },
  "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adults"])},
  "amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amenities"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartment"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
  "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
  "arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in"])},
  "attendants:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of attendants:"])},
  "automatically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically"])},
  "availableTickets": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("maxGroupSize")), " available tickets left"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "baseMultimedia": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add, title, change the display order, delete images."])}
  },
  "basicInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])},
  "bed_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bed"])},
  "beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beds"])},
  "bedsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed(s)"])},
  "beneficialOwners": {
    "beneficialOwners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficial owners"])},
    "beneficialOwnersLocationInAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More detailed information about beneficial owners can be found in Section 9 of the following Act"])},
    "linkToAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonian Money Laundering and Terrorist Financing Prevention Act"])},
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No beneficial owners have been specified."])},
    "whyWeCollectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touringery collects information about the beneficial owners of service providers in accordance with the local laws and requirements of our transaction processors."])}
  },
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
  "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth date"])},
  "book_keeping": {
    "goToTrigonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Trigon accounting program"])}
  },
  "bookingCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking created successfully"])},
  "bookingCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking creation failed"])},
  "bookingForm": {
    "buttons": {
      "addBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add booking"])}
    },
    "clientSection": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client information"])}
    },
    "editExistingClientAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are updating contact information of existing client instead of creating a new one.\nIn order to create a new client you have to clear existing client from selection"])},
    "fields": {
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of guests"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "timeslotDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking duration"])},
      "timeslotStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking start"])},
      "timeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])}
    },
    "loadingDatePickerTimeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading availabilities"])},
    "noTimeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free timeslots not found"])},
    "removeClientLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove client"])},
    "serviceSection": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article"])}
    },
    "timeslotsNotLoaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select sales article and then date in order to see free timeslots"])}
  },
  "bookingIntegration": {
    "activateConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate connection"])},
    "activateRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate room connections"])},
    "bookingAgreeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I understand and agree with integration's limitations and effect on the Booking.com channel"])},
    "bookingProperty": {
      "checkPropertyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update connection status"])},
      "refreshRoomRatesButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh rooms and rate plans"])}
    },
    "cancelConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel connection"])},
    "cancelRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel rooms connections"])},
    "closedRestrictionsCheckboxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have checked my closed restrictions in Booking.com"])},
    "completedErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration with Booking.com failed. Please cancel connections and try again, or contact our support"])},
    "completedSuccessMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Integration with Booking.com has successfully completed. ", _interpolate(_named("new_reservations_created")), " new reservations were created"])},
    "connectRoomsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to rooms"])},
    "connectToPropertyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to property"])},
    "connecting": {
      "activatePropertyFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now that necessary steps in Booking.com is done it is time to start connecting and mapping on our side."])},
      "activatePropertySecondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First thing is to create a connection with your property in booking.com. For that you need to enter your property ID of the property from booking.com and press \"Activate connection\"."])},
      "activatePropertyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecting with property"])},
      "activateRoomsFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the connection is activated it is time to map your property's rooms to their matching housings in Touringery. In order to activate housing connections you have to map all the listed rooms from booking.com"])},
      "activateRoomsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapping rooms"])},
      "activeConnectionFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After mapping and connecting the rooms successfully Touringery will start syncing availability, price and restrictions data to booking.com. Speed of syncing is dependant on overall integration activity and number of updates required to sync all the connections."])},
      "activeConnectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active connection"])},
      "closedRestrictionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is necessary to remove existing closed restrictions before setting up the integration. This is necessary because API does not enable us to remove previous closed restrictions and it will not be possible to remove them through Booking.com calendar when connectivity provider is activated."])},
      "closedRestrictionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed restrictions"])},
      "connectToConnectivityProviderLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Setting up and working with a connectivity provider "])},
      "connectivityProviderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The first thing that needs to be done is connecting to our supported connectivity provider at Booking.com."])},
      "connectivityProviderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectivity Provider"])},
      "connectivityProviderZodomusText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the supported connectivity provider is \"Zodomus\"."])},
      "connectivityTutorialLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steps needed to be taken for that are introduced in the following Booking.com article"])},
      "introText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For this integration we expect you to have an existing Booking.com property with rooms and room rates."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to connect"])}
    },
    "deleteConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete connection"])},
    "description": {
      "centralizedToTouringeryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This integration enables you to manage your property's availability, price and restrictions through Touringery."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])}
    },
    "housingHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose matching Touringery housing from the list"])},
    "limitations": {
      "bookingReservationsFunctionalityText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservations made in Booking.com's will appear in Touringery's calendar. It will be possible to see some basic client information. Mail template and check-in functionality will also apply."])},
      "durationPriceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration based price changes are not synced to Booking.com. Those only affect prices in Touringery."])},
      "initialConnectionUpdatesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largest amount of updates are required on initial connection as all the pricing and availability needs to be synced at that point. The amount of updates depends on the complexity of your connected housings pricing periods and existing number of reservations they have in Touringery."])},
      "numberOfUpdatesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of updates"])},
      "priceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
      "reservationsLimitedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New and existing future reservations will by synced from Booking.com to Touringery but they will have limited functionality in our system."])},
      "reservationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservations"])},
      "standardPriceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full prices specified in our housing's pricing periods will be set as standard price in Booking.com room rates."])},
      "standardPricingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com offers multiple pricing types from which we support only standard pricing model."])},
      "syncBookingReservationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the other hand, updates regarding reservations made in Booking.com are handled separatedly and are not affected by the length of the shared queue."])},
      "syncOnDemandText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After that syncing is done when relevant changes are made in Touringery. These include but are not limited to changes in housing periods, related preferences, related reservations and closures."])},
      "syncingFrequencyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syncing changes from Touringery to Booking.com is not instantaneous. All of the updates go to a shared queue, which means that the amount of time it takes to update your Booking.com property depends on the position that your updates have in it."])},
      "syncingTimeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update frequency"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitations"])}
    },
    "propertyActivationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in property activation"])},
    "propertyActivationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property successfully activated"])},
    "propertyCancellationnError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in cancelling connection"])},
    "propertyCancellationnSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conncection successfully cancelled"])},
    "propertyHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To start integration with Booking.com, first insert your property ID in booking. You can find that on booking.com admin site header right next to Booking logo."])},
    "propertyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property ID"])},
    "roomIdLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com room ID"])},
    "roomsActivationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing connections successfully activated"])},
    "roomsctivationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in activating housing connections"])},
    "statusActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated"])},
    "statusError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "statusInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com channel manager"])},
    "updateRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update room connections"])},
    "zodomusConnectedCheckboxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have set Zodomus as connectivity provider on Booking.com"])}
  },
  "bookingWidget": {
    "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
    "chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chosen"])},
    "errors": {
      "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ensure that the selected date/time range is available"])},
      "consecutiveSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ensure that the selected time range is consecutive"])},
      "slotsMaxDuration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please ensure that the selected time range does not exceed ", _interpolate(_named("maxDuration"))])},
      "slotsMinDuration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please ensure that the selected time range is at least ", _interpolate(_named("minDuration")), " long"])}
    },
    "goToBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to buy"])},
    "groupSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group size"])},
    "noAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No availability for this period"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
    "pickService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick a service to see availability"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "whatWillYouBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do you wish to buy?"])},
    "wholeDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " (whole day)"])}
  },
  "bookingsCalendar": {
    "onlyTransactionsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show only with transactions"])},
    "reservationDetails": {
      "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest"])},
      "openDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open booking"])},
      "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation origin"])},
      "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special requests"])},
      "seeAllBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all bookings from this guest"])},
      "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price"])}
    }
  },
  "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
  "businessDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business description"])},
  "businessInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company details"])},
  "businessName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business name"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "cancelBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel reservation"])},
  "cancelMessageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can describe the reason for the cancellation"])},
  "cancelOrder": {
    "cancelServerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error during order cancellation."])},
    "cancelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order successfully cancelled"])},
    "cannotCancelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot cancel this order at the moment"])},
    "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel this order?"])},
    "confirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel order"])},
    "removeOrderItemConfirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove this item from the order?"])},
    "removeOrderItemConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from order"])},
    "removeOrderItemServerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in removing item from order"])},
    "removeOrderItemSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully removed from order"])}
  },
  "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity"])},
  "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
  "cartOverview": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Overview for order #", _interpolate(_named("referenceNumber"))])},
  "cartPage": {
    "cartConfirmErrorAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error during cart confirmation"])},
    "cartConfirmSuccessAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart confirmed successfully."])},
    "cartConfirmedOrderExpiresAtAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Shopping cart has been confirmed and it expires at ", _interpolate(_named("expiresAt")), "."])},
    "clientConfirmedOrderExpiresAtAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Client information has been confirmed in addition to cart and it expires at ", _interpolate(_named("expiresAt")), "."])},
    "noItemsInCartText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping cart is empty"])},
    "orderStatusCancelledAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order is cancelled."])},
    "orderStatusPaidAlertWithTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order is in paid status. Download tickets."])},
    "orderStatusPaidAlertWithoutTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The order is in paid status."])},
    "orderStatusPendingAlertWithTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The transaction has been completed successfully. the final confirmation will be delivered to the email with a delay. Download tickets."])},
    "orderStatusPendingAlertWithoutTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The transaction has been completed successfully. the final confirmation will be delivered to the email with a delay."])},
    "orderStatusTimedOutAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order has timed out."])}
  },
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "ceoOrBoardMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executive director or board member"])},
  "changeHousingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change housing data"])},
  "channel": {
    "activateChannelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully connected to channel."])},
    "activateRoomsSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property rooms connected successfully."])},
    "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])}
  },
  "channels": {
    "activateChannelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to connect to channel."])},
    "activatePropertyRoomsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect rooms"])},
    "activateRoomsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to connect property rooms."])},
    "booking-widget-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public View"])},
    "bookingActivation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Booking.com channel"])}
    },
    "bookingProperty": {
      "checkPropertyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update connection status."])},
      "checkPropertySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection status updated."])},
      "refreshRoomRatesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to refresh property rooms and related rate plans."])},
      "refreshRoomRatesSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully refreshed property rooms and related rate plans."])}
    },
    "bookingPropertyChannelTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Property ", _interpolate(_named("property"))])},
    "channel-manager-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel Manager"])},
    "confirmPropertyDeactivationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to deactivate this channel?"])},
    "connectPropertyChannelLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "connectRoomsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to connect Booking.com rooms in your property you have to select their standard pricing plan and matching housing article."])},
    "deleteChannelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete the channel."])},
    "deleteChannelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel successfully deleted."])},
    "fetchPropertyChannelDetailsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load channel details."])},
    "header": {
      "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property ID"])}
    },
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No channels found."])},
    "noPropertyRoomsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No rooms found in property."])},
    "propertyConnectionNeededForRoomsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms will be shown after connecting to the property."])},
    "ratePlanLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate plan"])},
    "roomHousingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected housing article's housing units count should be equal or lower than the room's. Otherwise errors will be thrown when trying to sync higher availability than Booking.com will accept."])},
    "roomRatePlanInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate plan that should be updated based on data from Touringery. Only standard rate plans are supported."])},
    "roomTitleWithNameAndId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room ID"])},
    "statusLabel": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
      "PROPERTY_ACTIVATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially connected"])}
    },
    "tabLabel": {
      "bookingWidget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public View"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])}
  },
  "checkAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check availability"])},
  "checkIn": {
    "aboutClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About client"])},
    "aboutClientStaying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the reservation is purchased for others then do not select the following option. In case you have reservations for multiple rooms, please select the following option only for the reservation you plan to stay at."])},
    "aboutTravelCompanions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the form for everyone traveling with you"])},
    "aboutTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel data"])},
    "aboutYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data"])},
    "addTravelCompanion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add travel companion"])},
    "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional question"])},
    "additionals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional questions"])},
    "additionalsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can formulate additional questions, that will be added to the check-in form for chosen services. The answer is text field with max 1000 char"])},
    "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adult"])},
    "ageGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age group"])},
    "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When changing the question, keep in mind that it changes retrospectively to already saved questions too"])},
    "checkInDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check in details"])},
    "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child"])},
    "confirmCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I confirm that the provided data is correct"])},
    "detailsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of your trip"])},
    "downloadForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download form"])},
    "estimatedCheckInTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated check in time"])},
    "groupSizeHasChanged": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The number of visitors has changed, ", _interpolate(_named("guestsPlural")), " have been specified instead of the original ", _interpolate(_named("originalCount")), ". This may lead to changes in service"])},
    "hoursRange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Between ", _interpolate(_named("check_in_hours_range"))])},
    "housingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing details"])},
    "isClientStayingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client is staying in this reservation"])},
    "isForConferenceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attending a conference"])},
    "isNotSubmittedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please submit your data and save the form"])},
    "isSubmittedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form is submitted"])},
    "isWrongReservationTypeAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot submit form with current reservation status"])},
    "openCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to cart"])},
    "purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose of trip"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in saving data"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data successfully saved"])},
    "serviceSelectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select sales article to see relevant questions"])},
    "statusSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted"])},
    "statusUnSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubmitted"])},
    "tavelCompanion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel companion"])},
    "tavelCompanions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel companions"])},
    "travelData": {
      "clientHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])}
    },
    "travelPurpose": {
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
    },
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation"])},
    "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])}
  },
  "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
  "chooseCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose category"])},
  "chooseSubCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose subcategory"])},
  "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizenship"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City/Municipality"])},
  "cityRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Village/Town/District"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["client"])},
  "clientBookings": {
    "tableHeaders": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Article"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])}
    }
  },
  "clientCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client created successfully"])},
  "clientCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client creation failed"])},
  "clientInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client information"])},
  "clientSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client information is saved"])},
  "clientSavedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save client information"])},
  "clientTab": {
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookings"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])}
  },
  "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
  "clients_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client's name"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
  "color": {
    "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blue"])},
    "blue_green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teal"])},
    "blue_purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violet"])},
    "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green"])},
    "orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orange"])},
    "purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purple"])},
    "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
    "red_orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermilion"])},
    "red_purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magenta"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
    "yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yellow"])},
    "yellow_green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chartreuse"])},
    "yellow_orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amber"])}
  },
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "commentUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to add comment"])},
  "commentUpdateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment added!"])},
  "companyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company created successfully"])},
  "companyCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company creation failed"])},
  "companyLocation": {
    "confirmDeleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this location?"])},
    "createLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to create location"])},
    "createLocationModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create location"])},
    "createLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location successfully created"])},
    "deleteLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete the location"])},
    "deleteLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location successfully deleted"])},
    "fetchLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load location"])},
    "generalBlockTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General information"])},
    "updateLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update location"])},
    "updateLocationModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update location"])},
    "updateLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location successfully updated"])}
  },
  "companyLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company logo"])},
  "companyNotifications": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company notifications"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure automatic e-mail notifications sent to company e-mail"])},
    "notification": {
      "eventCancelDelayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event cancellation delayed"])},
      "eventCancelDelayedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent every time an automatic event cancellation has been temporarily delayed. Reason for delay can be a pending payment."])},
      "eventCancelPassed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event minimal participants reached"])},
      "eventCancelPassedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent when the event's minimum number of participants has been reached when checking for automatic cancellation. This means automatic cancellation will be disabled for given event."])},
      "eventCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event cancelled"])},
      "eventCancelledDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent every time an event is automatically canceled"])},
      "orderPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New order"])},
      "orderPaidDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent every time service provider's sales items have been paid for, i.e there is a new order created"])},
      "partnerInviteResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership invitation response"])},
      "partnerInviteResponseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent when your partnership invitation to a company has been accepted"])},
      "partnerPayoutChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership payout percentage change"])},
      "partnerPayoutChangeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent when the partner has changed their payout percentage"])},
      "partnerStatusChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership status change"])},
      "partnerStatusChangeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent when partnership status has been changed"])},
      "reservationCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation cancelled"])},
      "reservationCancelledDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent every time a customer has canceled their reservation"])}
    },
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send e-mail"])}
  },
  "companyRole": {
    "addRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add role"])},
    "alertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin role cannot be changed or deleted. There must be at least one person with admin role in company"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read"])},
    "removeRoleConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this role?"])},
    "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])}
  },
  "companyRoles": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "permissios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])}
  },
  "compucash": {
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash client name"])},
    "clientSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash client secret"])},
    "dataIsStillSyncing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash data is still synchronizing, please come back later."])},
    "deleteProduct": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete Compucash product ", _interpolate(_named("productId")), " - ", _interpolate(_named("productName"))])},
    "deleteProductConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure that you want to delete this product?"])},
    "deleteProductInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This delete action only deletes the compucash product data that has been saved in Touringery, this does NOT delete the Touringery service it is related to."])},
    "doubleCheckCompucashData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please double check Compucash integration data. For more information please contact Touringery or Compucash."])},
    "helperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data needed for compucash integration to synchronize the products from Compucash to Touringery. To get the necessary values, please contact Compucash."])},
    "makeIntoService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to service"])},
    "makeIntoServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect Compucash products to services"])},
    "newServiceForCompucashProduct": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["New service for compucash product ", _interpolate(_named("productId"))])},
    "productDeprecatedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Compucash sync response did not contain this product, possibly because it has been deleted or web sale has been forbidden. The related service has been set private to not allow any direct sales. If you will not need the service anymore, you have the option to archive it in the service settings. If this is a mistake, please check the product in Compucash."])},
    "productID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product ID"])},
    "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name"])},
    "serviceSettingsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash Product Settings"])},
    "serviceSettingsHeaderInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the service provider is connected to Compucash, here you can link an already published sales article with a Compucash product."])},
    "serviceSettingsUserAddedRelatedCompucashId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product ID in Compucash"])},
    "serviceSettingsUserAddedRelatedCompucashIdInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the Compucash product ID, used to sync an existing product in Touringery with Compucash instead of creating a new one."])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync status"])},
    "sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronize"])},
    "syncFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash synchronization failed. Please contact Touringery."])},
    "syncFinishedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash synchronization finished successfully."])},
    "syncStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronization started in the background"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash integration settings"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected VAT"])},
    "withoutServiceToggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show only products without service"])}
  },
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "confirmAndPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
  "confirmDialog": {
    "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])}
  },
  "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
  "confirmationEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation email successfully sent"])},
  "confirmationEmailSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problems occurred when sending the email"])},
  "confirmations": {
    "allDecisionsMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All parties have agreed to the change! If necessary, please ask the client to proceed with the payment by clicking the button below, and the provider(s) to refund the required amount."])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
    "decisionMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The change request has been sent. You will be notified by email when the other parties have made their decision."])},
    "decisionNotMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A change request has been made to your cart. Please review its contents. If you agree with the changes, please confirm."])},
    "info": {
      "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the change to take effect, the provider must confirm it. If the provider agrees, you will receive an email with a link to pay the missing amount."])},
      "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the change to take effect, the client must confirm it. The client will receive an email with the change request. If the client agrees, you will be notified by email."])}
    },
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit change"])}
  },
  "contactServiceProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "coordinates": {
    "DD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD (decimal degree)"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinates"])}
  },
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied!"])},
  "copyUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy URL"])},
  "countdown": {
    "inPast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in past"])}
  },
  "countryOfResidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of residence"])},
  "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["County"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
  "createBulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create bulk"])},
  "createNewResourceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new group"])},
  "createServiceDraftError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to create draft of sales article"])},
  "currentCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["current"])},
  "customerLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add customer labels"])},
  "customerLabelsAndComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer labels and comment"])},
  "customerRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer rating"])},
  "dataIsSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data is saved"])},
  "dataLoadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load data"])},
  "dataSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving data failed"])},
  "dataSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data saved successfully"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "dayNoHourOption": {
    "fromTheStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day start"])},
    "untilEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day end"])}
  },
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
  "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deadline"])},
  "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delay"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "deleteClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete client"])},
  "deleteResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete resource"])},
  "deleteTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete task"])},
  "departure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "disableTrackList": {
    "addItemModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New closure"])}
  },
  "disabledTrackItem": {
    "allExcludedValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "confirmDeleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
    "deleteTrackErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete closure."])},
    "deleteTrackSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closure deleted successfully."])},
    "exclusionTypeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of closure"])},
    "form": {
      "endLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End (excluded)"])},
      "startLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start (included)"])}
    },
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closure"])},
    "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "trackType": {
      "fullDisable": {
        "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close all guests"])},
        "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close all units"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close all"])}
      },
      "quantityDisable": {
        "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close some guests"])},
        "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close some units"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close some"])}
      }
    },
    "updateItemErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update closure."])},
    "updateItemSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closure successfully updated."])},
    "updateModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update closure"])}
  },
  "disabledTrackList": {
    "addItemButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add closure"])},
    "createItemErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to create closure."])},
    "createItemSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closure created successfully."])},
    "experience": {
      "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests"])}
    },
    "housing": {
      "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing units"])}
    },
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No closures."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closures"])}
  },
  "discount": {
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "inDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In days"])},
    "inHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In hours"])},
    "numOfDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many days"])},
    "numOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many guests"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "untilDaysLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (num of days)"])},
    "untilGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (num of guests)"])},
    "untilSumLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (how much)"])},
    "untilYearLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (how old)"])}
  },
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounts"])},
  "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
  "distanceToStop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance to stop"])},
  "documentExpDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document expiration date"])},
  "documentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document number"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "downloadInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download invoice"])},
  "downloadTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download ticket"])},
  "downloadTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download tickets"])},
  "dragHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag here"])},
  "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
  "durationManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration manually"])},
  "durationPicker": {
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])}
  },
  "durations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durations"])},
  "dynamicNameTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " total"])},
  "dynamicNight": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["nights"]), _normalize(["night"]), _normalize(["nights"])])},
  "dynamicNightWithCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " night"]), _normalize([_interpolate(_named("count")), " night"]), _normalize([_interpolate(_named("count")), " nights"])])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "editCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changed successfully"])},
  "editExpirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit expiration date"])},
  "editUsers": {
    "changeRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change user role"])},
    "editUsersLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit user"])},
    "goToRolesView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to roles view"])},
    "resendInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend invitation"])}
  },
  "editedOrder": {
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a modified shopping cart, which requires a refund to the customer. To see more details, press the \"Modified Shopping Cart Details\" button."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modified Shopping Cart Details"])},
    "editedContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed content of changes"])},
    "iban": {
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account number from which the customer paid for the shopping cart"])},
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
    },
    "noIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact the customer directly to process the refund."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modified Shopping Cart Details"])}
  },
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "emailNotificationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled emails"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
  "erfLogo": {
    "altText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["European Regional Development Fund logo"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
  "eventCalendarDetails": {
    "eventReservationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservations"])},
    "noEventPeriodsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No events."])},
    "noReservationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reservations."])}
  },
  "eventCancelAtInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Takes place if atleast ", _interpolate(_named("minCapacity")), " tickets are sold in total after ", _interpolate(_named("cancelDuration")), "."])},
  "eventCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event created successfully"])},
  "eventDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose this option, if you wish to create new event. Event is a one-time sales article that takes place on a specific time."])},
  "eventError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event creation failed"])},
  "eventOrderItemRefundableUntil": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fully refundable until ", _interpolate(_named("until")), " (2 days before the event). No refunds given when cancelling after that date."])},
  "eventResultCard": {
    "packagePriceLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Package price for up to ", _interpolate(_named("maxGroupSize")), " people"])},
    "priceForGroupText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Price for ", _interpolate(_named("groupSize"))])}
  },
  "eventService": {
    "eventIsCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event has been cancelled."])},
    "eventSalesEnded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket sale for event has ended."])}
  },
  "eventUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event update failed"])},
  "eventUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event updated successfully"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
  "eventsCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events created successfully"])},
  "eventsCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to create events"])},
  "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "experienceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose this option, if you wish to create new service."])},
  "experienceSearch": {
    "calendarLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading availabilities, this may take some time."])}
  },
  "experiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "farm/apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farm/apartment"])},
  "feedbackSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback sent successfully"])},
  "feedbackSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send feedback"])},
  "fileUploadFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File upload failed!"])},
  "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "fixedTimeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed timeslot"])},
  "forDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection date"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "full_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full address"])},
  "gender": {
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
  },
  "general_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General information"])},
  "general_information_unfilled_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General information must be filled in and saved before you can proceed with the form"])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
  "generatedEventsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated events"])},
  "goToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to cart"])},
  "googleCalendar": {
    "eventModal": {
      "relatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related resource"])},
      "relatedService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related service"])}
    },
    "settings": {
      "authError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error logging into Google account"])},
      "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
      "helperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To sync Touringery calendars with Google Calendar, sign in with your Google account and agree to the terms."])},
      "modal": {
        "calendarName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
        "calendarType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar Type"])},
        "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How long before the event?"])},
        "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
        "exampleHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cottage"])},
        "exampleHousingUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["units 1,2"])},
        "exampleTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cleaning"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
        "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
        "googleCalendarDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Calendar Description"])},
        "googleCalendarSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Calendar Settings"])},
        "googleCalendarSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Calendar Name"])},
        "groupSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group size"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "productEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("name")), "\" sold today: ", _interpolate(_named("quantity"))])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
        "resourceDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatic calendar from Touringery system for resource \"", _interpolate(_named("name")), "\""])},
        "resourceEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ": quantity ", _interpolate(_named("quantity"))])},
        "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save calendar settings"])},
        "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar settings saved!"])},
        "sendReminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive reminders before events"])},
        "serviceDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatic calendar from Touringery system for sale item \"", _interpolate(_named("name")), "\""])},
        "serviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales item name"])},
        "syncActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sync Enabled"])},
        "taskEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " (", _interpolate(_named("serviceName")), ", ", _interpolate(_named("unitName")), ")"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Google Calendar Settings"])},
        "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit(s)"])},
        "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait, saving might take a moment..."])}
      },
      "table": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminders"])},
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Calendar Settings"])}
    },
    "type": {
      "EVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
      "EXPERIENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
      "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing"])},
      "MONEYJAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money Jar"])},
      "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
      "RESOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])},
      "TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task"])}
    }
  },
  "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group name"])},
  "group_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group size"])},
  "groupedServices": {
    "discountPercentageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "groupedServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales articles"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "requiredLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
    "selectServicesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select sales articles which you want to add into the group"])},
    "selectServicesToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add sales articles"])}
  },
  "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests"])},
  "guestsCountriesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution of accomodated people by country of residence"])},
  "guestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the guests"])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])},
  "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House"])},
  "housing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing"])},
  "housingCalendar": {
    "changeReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change reservation"])},
    "goToCartView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart view"])},
    "goToReservationView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking view"])},
    "noTimelineEventsInPeriodText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reservations or closures during this period."])},
    "showInfo": {
      "availableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available units"])}
      },
      "disabledUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units excluded"])}
      },
      "reservationsCount": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved units"])}
      },
      "reservedAndAvailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Reserved ", "|", " Available) units"])}
      },
      "selectLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
      "totalAndAvailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Total ", "|", " Available) units"])}
      },
      "totalAndUnavailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Total ", "|", " Unavailable) units"])}
      },
      "totalUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total units"])}
      }
    },
    "unspecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unspecified"])}
  },
  "housingCalendarDetails": {
    "availableGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
    "availableHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
    "closedHousingUnitsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "guestsPerUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest per unit"])},
    "housingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housings"])},
    "noReservationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reservations."])},
    "reservationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservations"])},
    "reservedHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved"])},
    "totalGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "totalHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
  },
  "housingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose this option, if you wish to create new housing"])},
  "housingOrderCard": {
    "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adult"])},
    "adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adults"])},
    "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["child"])},
    "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["children"])}
  },
  "housingOvernightEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travellers from Estonia overnight"])},
  "housingOvernightNonEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travellers from other countries overnight"])},
  "housingRoom": {
    "roomAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room amenities"])},
    "seeAllAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])}
  },
  "housingSalesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accomodation capacity"])},
  "housingSalesTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing sales total"])},
  "housingUnit": {
    "displayAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display all rooms"])},
    "doorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door code"])},
    "housingUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing units"])},
    "identificator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificator"])},
    "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the amount of this type of accommodation and a unique identifier"])},
    "infoHelperDoorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door code (not obligatory) can be used as a variable in e-mail templates"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing unit"])}
  },
  "housings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housings"])},
  "howMany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many"])},
  "howManyAreYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many are you?"])},
  "howToGetThere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to get there"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "idCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID code"])},
  "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifier"])},
  "insertEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert e-mail"])},
  "invitationsSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitations sent successfully"])},
  "invitationsSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send invitations"])},
  "inviteNewUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite new users"])},
  "inviteUsersForm": {
    "roleAdminInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin - can do everything within the company, if there are several companies, then in all of them."])},
    "roleUserInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User - can view and edit tasks addressed to him, calendar (notification about changes is sent to the admin)."])}
  },
  "inviteUsersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited users get registered at Touringery and will receive an e-mail invitation to the site."])},
  "labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labels"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "languageEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "languageEt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonian"])},
  "languageFi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finnish"])},
  "languageJa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
  "languageRu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
  "languageServiceGeneralBanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the language you specified for the application when adding information to the fields."])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last update"])},
  "lastView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last view"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
  "loadingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "locationPublicTransport": {
    "addTransportLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add public transport"])},
    "distanceKmLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Km"])}
  },
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
  "logoExplanationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The logo is displayed in the side menu, login form and registration form. When displaying, the size of the logo scales depending on the space, but the maximum height is 108 pixels."])},
  "manageAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage account"])},
  "markAsDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as done"])},
  "markAsNotDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as not done"])},
  "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
  "mealPlanLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal plan"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "minAdvanceTimePastWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum reservation time beforehand has passed"])},
  "minParticipantsInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum of ", _interpolate(_named("minGroupSize")), " sold tickets required by ", _interpolate(_named("cancelTime"))])},
  "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
  "moneyJarCalendarDetails": {
    "noPaidOrderItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No moneyjars bought on this date."])},
    "orderItemsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money jars"])},
    "totalUnitsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold units"])}
  },
  "moneyJarDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose this option, if you wish to create new service-like sales article that enables you to sell items without taking account any availabiliy restrictions etc"])},
  "multimedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multimedia"])},
  "multimediaTab": {
    "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add images"])},
    "baseFileLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max 5 files at the time"])},
    "fetchMultimediaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to load sales article multimedia"])},
    "fileDeleteExistingHeaderFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete existing header"])},
    "fileDeleteFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Failed to delete file named \"", _interpolate(_named("heading")), "\""])},
    "fileHeadingIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading is mandatory."])},
    "fileHeadingTooLong": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum length for heading is 200 characters. Currently it is ", _interpolate(_named("length")), " characters."])},
    "fileOverLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["File \"", _interpolate(_named("name")), "\" size over 5MB"])},
    "fileUpdateFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Failed to update file named \"", _interpolate(_named("heading")), "\""])},
    "fileUploadFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Failed to upload file named \"", _interpolate(_named("heading")), "\""])},
    "fileUploadHeaderFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to upload new header"])},
    "fileWrongFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["File \"", _interpolate(_named("name")), "\" format is not supported. Supported: SVG, JPG, JEPG, PNG"])},
    "filesSaveFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save files"])},
    "filesSaveFailedPartially": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save some of the files"])},
    "filesSaveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files saved successfully"])},
    "gallery": {
      "altTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt Tag"])},
      "altTagHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add description text, in case photo not showing"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heading"])},
      "headingHelperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add photo heading. Maximum length is 200 characters."])},
      "maxSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended maximum size: 5MB"])},
      "minDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended minimum dimensions: 960 x 540 px"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])}
    },
    "instructions": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add attractive and good quality images."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that there are orientations about images sizes and dimensions that should be considered."])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo file names should preferably be in English."])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure that the intellectual property belongs to you and you are allowed to upload the multimedia to the tourist information system. When adding multimedia, you grant the administrator a free worldwide simple license to use the information you added."])}
    },
    "isServiceHeaderLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header photo"])},
    "ticket": {
      "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add image"])},
      "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add an image to your sales item's ticket "])},
      "minDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended minimum dimensions: 150 px wider side"])},
      "noPricesAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No prices to display"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket preview"])},
      "showPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display preview"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket"])}
    }
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "nearbyTransportStops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nearby public transport stops"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "newEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New event"])},
  "newResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New resource"])},
  "newService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New sales article"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["night"])},
  "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nights"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "noAvaiableDatesInMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This month does not contain avaiable dates"])},
  "noAvailabilityRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough availability on these dates. See other offerings from this provider "])},
  "noAvailabilityWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough availability on these dates. Please try different dates"])},
  "noEventAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no event periods. Please check you filters or create at least one period."])},
  "noExistingClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existing clients"])},
  "noOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No options"])},
  "noPermissionsForRouteAccessError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Access denied! You do no have the permissions to view page \"", _interpolate(_named("route")), "\"."])},
  "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results to display"])},
  "noRightsErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access denied. You are not authorized to perform this action"])},
  "noSearchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results with that query"])},
  "noTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tasks"])},
  "notAccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not accepted"])},
  "notDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not done"])},
  "notReadyYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section is under construction"])},
  "numberOfRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of rooms"])},
  "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "openDetailView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open details view"])},
  "openPartnershipDetailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open partner view"])},
  "openTimeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open timeslot"])},
  "optionSelectedLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("length")), " options selected"])},
  "order": {
    "confirmedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed at"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
    "invoices": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Order number"]), _normalize(["Order numbers"])])},
    "paidAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid at"])}
  },
  "orderClientPage": {
    "addressDiscaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You only need to input your address if you want an invoice"])},
    "agreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and agree with the terms of use"])},
    "confirmClientError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error during client information confirmation"])},
    "confirmClientSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client information successfully confirmed"])},
    "contactInfoDiscaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We only use your data to contact you if necessary"])},
    "tourTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General terms"])},
    "updateClientError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error occured during update of order client"])},
    "updateClientSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order client successfully updated"])}
  },
  "orderConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order confirmation"])},
  "orderSummary": {
    "cancellationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order cancellation"])},
    "refundableLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refundable"])},
    "refundedLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunded"])}
  },
  "order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice number"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origin"])},
  "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
  "overviewCalendar": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
    "calendarSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar selection"])},
    "calendars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendars"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("eventsNr")), " more"])},
    "multidayEventsInTimeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show events spanning multiple days within the timeline"])},
    "noCalendars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No calendars"])},
    "noOrdersInPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No orders in this period"])},
    "orderCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders' overview"])},
    "orderNo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Order #", _interpolate(_named("referenceNumber"))])},
    "orderType": {
      "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
      "CONFIRMED_OFFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
      "CONFIRMED_ORDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed Order"])},
      "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
      "LOCKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked"])},
      "OFFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer"])}
    },
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
    "otherDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On other days"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])}
  },
  "overviewTabs": {
    "bookingCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
    "bookingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookings"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])}
  },
  "participants": {
    "maxGroupSizeText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("groupSize")), "/", _interpolate(_named("maxGroupSize")), " selected"])},
    "participantsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])}
  },
  "partnership": {
    "acceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept partnership"])},
    "acceptPartnershipInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If both sides have accepted the partnership then your sales articless will be shown on each other's pages. Revenue sharing functionality will also be turned on again."])},
    "backToPartnersList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all partnerships"])},
    "companyHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First partner"])},
    "confirmAcceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm acceptance"])},
    "confirmUnacceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm deactivation"])},
    "detailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership details"])},
    "partnerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second partner"])},
    "payoutPercentageExampleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For example, during active partnership your partner's sales articles pages will also show your sales articles in addition to theirs. When a customer open your sales article and adds it to their cart, your partner will be eligible for the payout share that you have specified."])},
    "payoutPercentageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage of payout that company is willing to share with their partner. Sharing only occurs when a sale is reached directly through partner's sales articles."])},
    "payoutPercentageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner's payout share"])},
    "payoutPercentageModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner will be notified when your payout share changes."])},
    "statusLabel": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])}
    },
    "tabs": {
      "inviteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invites"])},
      "partnerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
    "unacceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate partnership"])},
    "unacceptPartnershipInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivating partnership means your sales articles will not be shown on each other's pages. Revenue sharing functionality will also be disabled for both sides. Future payouts from earlier revenue sharing will not be affected."])},
    "updatePartnershipAcceptanceErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update partnership."])},
    "updatePartnershipAcceptanceSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership updated successfully."])},
    "updatePartnershipPayoutPercentageErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to update partner's payout share."])},
    "updatePartnershipPayoutPercentageSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated partner's payout share."])},
    "updatePayoutPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change payout share"])}
  },
  "partnershipInvite": {
    "acceptInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept invite"])},
    "acceptInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to accept partner invite."])},
    "acceptInviteRegisterFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not have a service provider account in Touringery yet, then you will need to go through the registration process before retrying the invitation."])},
    "acceptInviteRequiresLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to accept the invite, you have to log in to your service provider account first."])},
    "acceptInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner invite accepted succesfully. You will be redirected to partner details view."])},
    "acceptInviteVisitorModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login required"])},
    "companyWantsYouToBecomeTheirPartner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("company")), " is inviting you to become their partner in Touringery."])},
    "createInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send partner invite."])},
    "createInviteModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New partner"])},
    "createInviteSubmitButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invite"])},
    "createInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner invite was sent successfully."])},
    "detailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership invite"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
    "inviteFormInfoHelperFirstParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner invitation will be sent to specified email. Email will contain a link, which will take recipient to partner invitation details view. There they can either accept or reject the invite."])},
    "inviteFormInfoHelperSecondParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to accept the invite, visitor must log in as a user of another service provider. Rejection can be done by any visitor with access to the link."])},
    "inviteInFinalStatusText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite has already received a response."])},
    "reinviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending reinvite failed."])},
    "reinviteLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invite again"])},
    "reinviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite successfully sent again."])},
    "rejectInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject invite"])},
    "rejectInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to reject invite."])},
    "rejectInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite has been rejected."])},
    "statusLabel": {
      "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
      "DECLINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])},
      "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
      "OPENED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opened"])},
      "SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])}
    },
    "whatHappensWhenYouAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\nPartnership enables you to show other offerings around you. You and your partner will also claim a small percentage of the sale, if it is reached directly through your links."])}
  },
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "passwordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
  "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changed!"])},
  "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
  "paymentBeneficiaryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiary name"])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment details"])},
  "paymentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment info"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
  "paymentOption": {
    "bankLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank payment"])},
    "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card"])}
  },
  "payoutHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout"])},
  "payoutInfo": {
    "completedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout is done."])},
    "initiatedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout process has been initiated."])},
    "noPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payout will be given. This happens when cancellation takes place with full refund."])},
    "notReadyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default payout status. It will change after reservation is completed or cancelled."])}
  },
  "payoutStatus": {
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid out"])},
    "initiated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiated"])},
    "noPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payout"])},
    "notReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On hold"])}
  },
  "payoutStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout statuses"])},
  "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["people"])},
  "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
  "periodAvailability": {
    "availabilityHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
    "availabilityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the day and time during which the resource is available"])}
  },
  "periodUnit": {
    "monthLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "selectMonthLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select month"])},
    "selectPeriodLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select period"])},
    "selectUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "weekLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])}
  },
  "permissions": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "helper": {
      "addCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can add new company"])},
      "readCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can see calendar information"])},
      "readClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can see client information"])},
      "readCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can see channel manager and settings content"])},
      "readReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can see bookkeeping and reports content"])},
      "readResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can see resources"])},
      "readServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can see sales articles and additional services information"])},
      "readTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can see tasks information"])},
      "readTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can see ticket information"])},
      "updateClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can edit client information"])},
      "updateCompanyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can edit company contracts, payment data, channel manager and partnership settings"])},
      "updateCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can edit company general info and company location info"])},
      "updateCompanyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can edit company user roles and set rights"])},
      "updateCompanyWorkflowHelpers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can edit company mail templates and check-in form questions"])},
      "updateReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "updateResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can edit resources"])},
      "updateServiceContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can add new sales article and new additional service. Can edit sales article's and additional service's general content and multimedia"])},
      "updateServiceIsPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can publish and archive sales article and additional service"])},
      "updateServicePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can edit sales article's resources, business hours, prices, housing specific info from the sales article's menu item and also from the calendar"])},
      "updateTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can edit tasks"])},
      "updateTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket validation rights"])}
    },
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
    "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales articles"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
    "updateCompanyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company payments, contracts"])},
    "updateCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company settings"])},
    "updateCompanyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company users"])},
    "updateCompanyWorkflowHelpers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company workflow helpers"])},
    "updateServiceContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales articles content"])},
    "updateServiceIsPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales articles publishing"])},
    "updateServicePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales articles policy"])}
  },
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
  "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["photos"])},
  "pleaseAgreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please mark that you have agreed to terms to proceed"])},
  "pleaseSelectBank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select payment provider"])},
  "pleaseSelectPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select payment method"])},
  "plurals": {
    "adult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " adults"]), _normalize([_interpolate(_named("count")), " adult"]), _normalize([_interpolate(_named("count")), " adults"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " children"]), _normalize([_interpolate(_named("count")), " child"]), _normalize([_interpolate(_named("count")), " children"])])},
    "elderly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " elderly"]), _normalize([_interpolate(_named("count")), " elder"]), _normalize([_interpolate(_named("count")), " elderly"])])},
    "numberOfAdditionalServices": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " additional services"]), _normalize([_interpolate(_named("count")), " additional service"]), _normalize([_interpolate(_named("count")), " additional services"])])},
    "numberOfGuests": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " guests"]), _normalize([_interpolate(_named("count")), " guest"]), _normalize([_interpolate(_named("count")), " guests"])])},
    "numberOfNights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " nights"]), _normalize([_interpolate(_named("count")), " night"]), _normalize([_interpolate(_named("count")), " nights"])])},
    "numberOfParticipants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " participants"]), _normalize([_interpolate(_named("count")), " participant"]), _normalize([_interpolate(_named("count")), " participants"])])},
    "numberOfRooms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " rooms"]), _normalize([_interpolate(_named("count")), " room"]), _normalize([_interpolate(_named("count")), " rooms"])])},
    "numberOfTickets": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " tickets"]), _normalize([_interpolate(_named("count")), " ticket"]), _normalize([_interpolate(_named("count")), " tickets"])])}
  },
  "politicallyExposed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politically exposed person"])},
  "preferences": {
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance (km)"])},
    "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guests"])},
    "includedInPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Included in price"])},
    "labels": {
      "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibility"])},
      "amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amenities"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "checkInHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check in hours"])},
      "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
      "difficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficulty"])},
      "furtherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further information"])},
      "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
      "numOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of guests"])},
      "stayDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay duration"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])}
    },
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
    "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferences"])}
  },
  "preferredLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred language"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "previousMonthResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous month results"])},
  "price": {
    "additionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional services"])},
    "additionalServicesVAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional services VAT (20%)"])},
    "createTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create ticket"])},
    "descriptionPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price description (optional)"])},
    "fullPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full price"])},
    "isPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package price"])},
    "maxGroupSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max group size"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price name"])},
    "priceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "servicePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article price"])},
    "serviceVAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article VAT (20%)"])}
  },
  "priceDynamicRows": {
    "addRowLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add price change"])},
    "durationFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does the duration affect the price?"])},
    "durationInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have specified that duration affects pricing. This means you can specify price changes for each duration time unit that modify their final price."])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration price changes consist of following fields"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of duration price changes"])},
      "isImportantHourlyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With hourly pricing type duration is always important and can not be disabled."])},
      "isImportantNightlyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With nightly pricing type duration is always important and can not be disabled."])},
      "isImportantPackagedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With packaged pricing type duration is not important and can not be enabled."])},
      "isPriorityHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If checked, this price change will also be applied to all the earlier hours, overriding any other price changes that might have been applied to them."])},
      "isPriorityNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If checked, this price change will also be applied to all the earlier nights overriding any other price changes that might have been applied to them."])},
      "lowerBoundHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower limit of hours for given price change bounds. Lower limit is inclusive, price changes will apply to it."])},
      "lowerBoundNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower limit of nights for given price change bounds. Lower limit is inclusive, price changes will apply to it."])},
      "rowsHourlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default each hour of the reservation will have its price set to previously specified base price. Here you can modify price for each hour by adding price change rules. More information in info icon next to the heading."])},
      "rowsNightlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default each night of the reservation will have its price set to previously specified base price. Here you can modify price for each night by adding price change rules. More information in info icon next to the heading."])},
      "rowsPackagedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration has no effect for packaged type pricing. Price will be for the whole reservation instead of each hour/night."])},
      "upperBoundHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper limit of hours for given price change bounds. Upper limit is exclusive, price changes does not apply to it.\nUpper limit is not required but only one duration rule can be limitless."])},
      "upperBoundNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper limit of nights for given price change bounds. Upper limit is exclusive, price changes does not apply to it.\nUpper limit is not required but only one duration rule can be limitless."])}
    },
    "durationRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration based price changes"])},
    "groupSizeFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does the group size affect the price?"])},
    "groupSizeInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have specified that group size affects pricing. This means you have to specify at least one price change."])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group size price changes consist of following fields"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of group size price changes"])},
      "isPriorityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If checked, this price change will also be applied to all the earlier group members, overriding any other price changes that might have been applied to them."])},
      "lowerBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower limit of persons for given price change bounds. Lower limit is inclusive, price changes will apply to it."])},
      "rowsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By default, when group size is important group size will be used as a multiplier to get the final price. Here you can modify how each additional person will affect price by defining price changes. More information in info icon next to the heading."])},
      "upperBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper limit of persons for given price change bounds. Upper limit is exclusive, price changes does not apply to it.\nUpper limit is not required but only one duration rule can be limitless."])}
    },
    "groupSizeRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group size based price changes"])},
    "hourUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per hour"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hour"])}
    },
    "lowerBoundLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "nightUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nights"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per night"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Night"])}
    },
    "noOverlapRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price change conditions can not overlap"])},
    "onlyOneLimitlessRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only one price change's condition can have a unspecified (limitless) upper bound"])},
    "personUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persons"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per person"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])}
    },
    "priceChangeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value that is added to final price if reservation matches rule's condition."])},
    "priceChangeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price change"])},
    "priorityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply to previous"])},
    "quantityUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantities"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per quantity"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])}
    },
    "relatedResourceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which related resource's required quantity is given price change based on."])},
    "relatedResourceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related resource"])},
    "relatedResourcePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "resourceConditionsPerRelatedResourceRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For related resource price changes, overlap restriction can only happen between price changes that are connected to the same related resource."])},
    "resourcesFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do related resources affect the price?"])},
    "resourcesInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have specified that related resources affect pricing. This means you have to specify at least one price change."])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource price changes consist of following fields"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of related resources price changes"])},
      "isPriorityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If checked, this price change will also be applied to all the earlier related resource quantities/hours, overriding any other price changes that might have been applied to them"])},
      "lowerBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower limit of resource quantity/horus for given price change bounds. Lower limit is inclusive, price changes will apply to it."])},
      "rowsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When resources are important you can specify how each added resource affects the final price.\nFor resources that represent people, price will change based on number of hours.\nFor resources that represent objects, price will change based on quantity of resources required for reservation. More information in info icon next to the heading."])},
      "upperBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper limit of resource quantity/hours for given price change bounds. Upper limit is exclusive, price changes does not apply to it.\nUpper limit is not required but only one duration rule can be limitless.\n"])}
    },
    "resourcesRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources base price changes"])},
    "restrictionsSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
    "upperBoundLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until"])}
  },
  "pricingProperties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing properties"])},
  "pricingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing type"])},
  "pricingTypes": {
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly"])},
    "moneyJar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money jar"])},
    "nightly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nightly"])},
    "packaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packaged"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])}
  },
  "pricingTypesInfo": {
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket prices (adult, elderly, children) are for the whole event."])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entered price is for each hour and will be multiplied by reservation duration.\nCurrently you can specify the minimum and maximum duration.\nIMPORTANT: \nCurrently starting times cannot be limited, client can choose any that fits business hours and is not reserved.\nPrice is not affected by group size. 2 hour reservation would cost the same for one person and a group of 10 persons.\nCurrently only one simultaneous reservation is supported."])},
    "moneyJar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final price for the customer. Service provider defines the sales article"])},
    "nightly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price will be calculated based on number of nights in reservation. \nIMPORTANT:\nCurrently the price for the whole reservation is based on the pricing of the first night."])},
    "packaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entered price is for the whole reservation duration which you can specify when choosing this pricing type.\nIMPORTANT: \nCurrently starting times cannot be limited, that is up to the client.\nPrice is not affected by group size. 2 hour reservation would cost the same for one person and a group of 10 persons.\nCurrently only one simultaneous reservation is supported."])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price will be based on quantity"])}
  },
  "priority": {
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical"])},
    "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
    "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgency"])}
  },
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
  "productCalendarDetails": {
    "noSalesForDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No products purchased for this date"])}
  },
  "profileSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile settings"])},
  "publicNoServiceBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks like you're heading to wrong destinations."])},
  "publicNoServiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oh no!"])},
  "publicService": {
    "archivedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article is not available"])}
  },
  "publicTransport": {
    "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airport"])},
    "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus"])},
    "harbor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harbor"])},
    "train": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Train"])},
    "tram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tram"])},
    "trolley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trolleybus"])}
  },
  "purchasedAtDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase date"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
  "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
  "readLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read less"])},
  "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
  "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
  "recipientWithPlural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient/Recipients"])},
  "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipients"])},
  "recommendedCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in at"])},
  "recommendedCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out at"])},
  "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])},
  "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refused"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "registration": {
    "searchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search company"])}
  },
  "registrationCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration country"])},
  "registrationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account creation failed"])},
  "registrationSuccessEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration was successful! An activation email was sent your mailbox"])},
  "registryNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registry code"])},
  "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
  "relate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relate"])},
  "relatedAvailableServices": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available on selected dates"])}
  },
  "relatedService": {
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])}
  },
  "relatedServices": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in addition"])},
    "partnersHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in addition from partners"])}
  },
  "relatedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related users"])},
  "related_check_in_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related check-in questions"])},
  "related_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related resources"])},
  "related_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related sales articles"])},
  "related_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related mail templates"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "removeAllLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove all"])},
  "report": {
    "deposit": {
      "header": {
        "commissionsDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission fee"])},
        "depositsBeforePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance before the period"])},
        "depositsDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposits received"])},
        "depositsEndOfPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance after the period"])},
        "paidAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit date"])},
        "partnersPayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners payouts"])},
        "payoutAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout date"])},
        "refundedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund date"])},
        "refundedDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunds"])},
        "serviceProviderPayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service provider payouts"])}
      }
    },
    "errorFetchData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report generating failed"])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
    "generateCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate csv"])},
    "generatePdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate pdf"])},
    "partners": {
      "header": {
        "payoutAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payouts"])},
        "payoutTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout receiver"])}
      }
    },
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
    "sales": {
      "header": {
        "clientsCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client's country"])},
        "clientsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client's name"])},
        "commissionFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission fee"])},
        "invoiceNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice number"])},
        "net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NET"])},
        "partnerPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner's share"])},
        "payoutDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout date"])},
        "priceLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price line"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
        "refundFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund fee"])},
        "refundedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refunded date"])},
        "salesArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article"])},
        "salesReportGenerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales report generated"])},
        "sumWithVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum with VAT"])},
        "sumWithoutVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum without VAT"])},
        "totalVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total vat"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT(%)"])}
      }
    },
    "table": {
      "additionalService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional service"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
      "housing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing"])},
      "moneyjar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneyjar"])}
    },
    "tabs": {
      "bookKeeping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bookkeeping"])},
      "depositReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit report"])},
      "partnersReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners report"])},
      "salesReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales report"])},
      "statisticsReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics report"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "touringeryCommission": {
      "header": {
        "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net"])},
        "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total sales"])}
      }
    }
  },
  "reports": {
    "statistics": {
      "accomodatedEstonianNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residents of Estonia - number of nights spent"])},
      "accomodatedEstonianPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residents of Estonia - number of accommodated tourists"])},
      "accomodatedForeignNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreign visitors - number of nights spent"])},
      "accomodatedForeignPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreign visitors - number of accommodated tourists"])},
      "accomodatedNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of nights spent"])},
      "accomodatedPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accommodated tourists"])},
      "bedPlacesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed places"])},
      "campingPlacesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of places on camping grounds and in trailer parks"])},
      "countryCodeHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code"])},
      "openDaysLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of days opened"])},
      "roomNightsSoldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use of housing units (number of sold room nights)\t"])},
      "roomsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of housing units"])},
      "tripPurpose": {
        "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business trip"])},
        "conference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["..participation in conference"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other (e.g., nights spent within transit tourism, medical tourism, etc.)\t"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of accommodated tourists and nights spent"])},
        "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holiday trip"])}
      }
    }
  },
  "requestLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request link"])},
  "requestNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request new password"])},
  "requestSentLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request sent! We have sent a password reset link."])},
  "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
  "requiredSettingsAlert": {
    "beneficialOwnersNotFilledText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficial owners/owners"])},
    "companyContractNotAgreedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agree to terms of the contract"])},
    "companyNoServiceContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales contract between service provider and customer"])},
    "dynamicShowAllLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Show more (", _interpolate(_named("count")), ")"])},
    "hideMoreSettingsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide more"])},
    "paymentInfoNotFilledText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment details for payouts"])}
  },
  "requirementsFilledError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error occurred. Please check if everything is filled correctly"])},
  "resendConfirmationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send order confirmation"])},
  "resendConfirmationEmailAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can resend the order confirmation to the customer."])},
  "resendConfirmationEmailAlertWithLastDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Here you can resend the order confirmation to the customer. Previous one was sent: ", _interpolate(_named("emailConfirmationAt")), "."])},
  "reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation"])},
  "reservationData": {
    "cannotChangePeriodForExternalSourcesAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot change period because reservation comes from an external source."])},
    "originalGuestsAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The number of guests has changed, initially it was: ", _interpolate(_named("guests"))])}
  },
  "reservationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation date"])},
  "reservationEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation end"])},
  "reservationHousingUnit": {
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in saving housing unit"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing unit successfully saved"])}
  },
  "reservationLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length of reservation"])},
  "reservationSalesEndTimeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online sales end date"])},
  "reservationStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation start"])},
  "reservationStatus": {
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])}
  },
  "reservationStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation statuses"])},
  "reservationType": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relation type"])},
    "oneCapacityPerPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One capacity per person"])},
    "oneQuantityPerEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One quantity per event"])},
    "oneQuantityPerPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One quantity per person"])},
    "oneQuantityPerReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One quantity/hour per reservation"])}
  },
  "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])},
  "resourceCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource created successfully"])},
  "resourceCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource creation failed"])},
  "resourceDefaultTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role/Title"])},
  "resourceDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource deleted successfully"])},
  "resourceEventName": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar Event Title"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The calendar event will appear in the general view and Google Calendar with this title."])}
  },
  "resourceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "resourceGroupCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group create"])},
  "resourceGroupCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group created successfully"])},
  "resourceGroupCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group creation failed"])},
  "resourceGroupUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group update"])},
  "resourceGroupUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group update failed"])},
  "resourceGroupUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group updated successfully"])},
  "resourceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
  "resourceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource name"])},
  "resourceObjectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "resourcePeriodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the date during which the resource is available"])},
  "resourceSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource saved successfully"])},
  "resourceSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving resource failed"])},
  "resourceTabs": {
    "resourceCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource calendar"])},
    "resourceGroupList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource groups"])},
    "resourceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])}
  },
  "resourceUserTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
  "respondedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responded by"])},
  "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "room": {
    "alertinfotext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A housing unit is an accommodation for a guest / group of guests. Please add each different housing unit separately; or add the number of your (similar) housing units"])},
    "maxGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of guests"])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
    "roomInfoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the number of rooms in this housing units, to specify information about each room"])},
    "roomType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room type"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rooms"])},
    "unitCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])}
  },
  "roomAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room amenities"])},
  "roomLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General info"])},
  "roomsSoldEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travellers from Estonia"])},
  "roomsSoldNonEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travellers from other countries"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rows per page"])},
  "rrule": {
    "end:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End:"])},
    "endDate:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date:"])},
    "eventInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event information"])},
    "every": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every"])},
    "everyDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". date every"])},
    "everyDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every day"])},
    "everyMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every month"])},
    "everyOtherDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
    "everyOtherMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
    "everyOtherWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["week"])},
    "everyTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["times"])},
    "everyWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every week"])},
    "everyWorkDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every working day"])},
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first"])},
    "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fourth"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["friday"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monday"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
    "recurresEvery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurres every"])},
    "repetitionFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetition frequency"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saturday"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["second"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sunday"])},
    "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["third"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thursday"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tuesday"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wednesday"])}
  },
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "saveAndContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and continue"])},
  "saveNewCoordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get map coordinates"])},
  "scanner": {
    "anonymousCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymous Camera"])},
    "cameraAccessSecureContextOnlyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera access is only supported in secure context like https or localhost."])},
    "cameraBasedScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera based scan"])},
    "cameraStreamingNotSupportedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera streaming not supported by the browser."])},
    "chooseAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Another"])},
    "chooseImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Image"])},
    "chooseImageNoImageChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Image - No Image choosen"])},
    "codeScanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Scanner"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "fileBasedScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File based scan"])},
    "getUserMediaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error getting userMedia, error ="])},
    "idle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idle"])},
    "launchingCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launching camera..."])},
    "loadingImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading image..."])},
    "navigatorMediaDevicesNotSupportedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The device doesn't support navigator.mediaDevices , only supported cameraIdOrConfig in this case is deviceId parameter (string)."])},
    "noCameraFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No camera found"])},
    "noCameras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cameras"])},
    "noImageChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No image chosen"])},
    "notAllowedPermissionDeniedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NotAllowedError: Permission denied"])},
    "orDropImageToScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or drop an image to scan"])},
    "orDropImageToScanOtherFilesNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or drop an image to scan (other files not supported)"])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])},
    "poweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by"])},
    "qrCodeParseError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR code parse error, error ="])},
    "querySupportedDevicesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to query supported devices, unknown error."])},
    "reportIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report issues"])},
    "requestCameraPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Camera Permissions"])},
    "requestingCameraPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request camera permissions..."])},
    "scanAnImageFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan an Image File"])},
    "scanUsingCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan using camera directly"])},
    "scannerPaused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner paused"])},
    "scanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanning"])},
    "selectCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Camera"])},
    "startScanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start scanning"])},
    "stopScanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Scanning"])},
    "switchOffTorch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch Off Torch"])},
    "switchOffTorchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to turn off torch"])},
    "switchOnTorch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch On Torch"])},
    "switchOnTorchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to turn on torch"])},
    "zoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zoom"])}
  },
  "scheduledEmails": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled e-mails"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No emails"])},
    "scheduledAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled at"])},
    "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send email"])},
    "sendEmailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending the e-mail failed "])},
    "sendEmailSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email successfully sent"])},
    "sentAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last sent at"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template"])}
  },
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "searchCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search engine category"])},
  "searchExistingClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search from existing clients"])},
  "searchForAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for address"])},
  "searchToSeeResultsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter dates and visitor info to see availability"])},
  "seeAllClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all clients"])},
  "seeAllResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all resources"])},
  "seeAllServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all sales articles"])},
  "seeAllTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all tasks"])},
  "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
  "selectAdditionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select additional services"])},
  "selectAdditionalServicesToRelate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the additional services below that you want to relate"])},
  "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select company"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select country"])},
  "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select date"])},
  "selectRelatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select related resource"])},
  "selectResourcesToRelate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the resources below that you want to relate"])},
  "sendInvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invitations"])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send message"])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article"])},
  "serviceAdditionalService": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can link additional products previously entered from the sales articles menu. Selected additional products are displayed in the shopping cart before payment."])}
  },
  "serviceAdvancedTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
  "serviceArchiveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to archive sales article"])},
  "serviceArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article successfully archived"])},
  "serviceCheckInQuestions": {
    "addQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add question"])},
    "addRelationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose questions to add to - or remove from - sales article's check-in form"])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The changes will apply to all related sales articles"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related check-in questions"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Settings - Check-in Questions for all questions. Here you can add questions to current service item"])},
    "saveRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save relations"])},
    "selectQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit related questions"])},
    "serviceCreateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All sales articles' check-in form additional questions are in Settings -> Check in Form"])}
  },
  "serviceContact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
    "headerDefaultsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields without values default to company profile settings as seen in placeholders."])},
    "headerGeneralInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify sales article specific contact information for clients. "])},
    "nameInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the contact person. Field is hidden from clients when left empty."])},
    "overrideNotificationsGeneralInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When checked, given e-mail will be used instead of company e-mail for sending e-mail notifications in regards to this service."])},
    "overrideNotificationsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use for e-mail notifications"])},
    "overrideNotificationsSettingsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send e-mail notification settings still apply."])}
  },
  "serviceDuplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article successfully duplicated"])},
  "serviceDuplicationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article duplication failed"])},
  "serviceEvent": {
    "availableCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available capacity"])},
    "cancelAtHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation time"])},
    "cancellationTimeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can set the date and time by which the minimum number of participants must be met/the minimum amount of tickets sold. If the minimum number of participants is not met, the event is canceled automatically. The money received for the tickets purchased so far will be returned, the participants will be notified automatically. The cost of transfers associated with the cancellation of the event must be reimbursed by the organizer."])},
    "capacityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your event requires a minimum number of participants, enter it here. If the event takes place anyway, there is no need to specify a minimum, just enter the maximum number of participants."])},
    "eventCapacityHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacity"])},
    "eventPeriodHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event time"])},
    "maxCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum"])},
    "minCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "timeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the start date of the event from the calendar, click on the clock icon and select the start time."])},
    "updateEventTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update event"])}
  },
  "serviceEventCalendar": {
    "onlyEventsFilterLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show only with event"])}
  },
  "serviceEventName": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar Event Title"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The calendar event will appear in the general view and Google Calendar with this title."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title successfully saved!"])}
  },
  "serviceEventsArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events archived successfully"])},
  "serviceEventsArchivedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to archive events"])},
  "serviceFilter": {
    "privateState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])}
  },
  "serviceGeneral": {
    "categoryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the closest possible."])},
    "descriptionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If necessary, stretch the window from the bottom right corner to a larger one."])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These are for internal use by the service provider and allow different sales items to be grouped according to their needs and filtered based on them in the list view."])},
    "searchCategoryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This category is shown for the user and is used for searching and filtering services."])},
    "typeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category specification."])},
    "vatCategoryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This category is hidden for the clients. The system selects the sales item's VAT percentage according to this option."])}
  },
  "serviceGroup": {
    "groupItemIsUnavailableForSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article is not available based on the selections"])}
  },
  "serviceLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add service labels"])},
  "serviceLocation": {
    "accessInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark all options that work."])},
    "addressInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search address uses Google search - enter your address as you can be found on Google."])},
    "howToGetThereInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe under which name, how you can be found in map applications and/or the route from the nearest center of attraction to you."])},
    "locationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move the mouse to the desired place on the map and click, a pin will appear, move the pin to the exact place if necessary."])},
    "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select location"])},
    "transportInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark the nearest stop and the distance from the destination."])}
  },
  "serviceLocationInfo": {
    "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill coordinates automatically by clicking on option in \"Search for address\" field. Fill them manually, or relocate pin on the map and push \"Get map coordinates\" button"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shown in location list to help identify specific locations"])}
  },
  "serviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article name"])},
  "serviceNotSavedWarning": {
    "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discard changes"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to continue without saving!"])}
  },
  "servicePeriod": {
    "activeHoursInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article availability by week and day."])},
    "arrivalLengthOfStayLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival based length of stay"])},
    "lengthOfStayLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length of stay"])},
    "periodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify the period during which you offer the sales article. If the sales article is available all year round, then for a period of a calendar year or more. If you don't want the period to expire leave end of period empty."])},
    "restriction": {
      "info": {
        "arrivalLengthOfStayDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the minimum and maximum number of nights guests guests can book when arriving on specific date. These restrictions must be passed based on the arrival date of booking period."])},
        "bookingArrivalLengthOfStay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\nThese restrictions are equivalent to Booking.com minimum and maximum length of stay from arrival restrictions."])},
        "bookingLengthOfStay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\nThese restrictions are equivalent to Booking.com minimum and maximum length of stay restrictions."])},
        "lengthOfStayDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\nThis is the minimum and maximum number of nights guests can book. These restrictions must be passed for all the dates in booking period."])},
        "restrictionSectionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specified restrictions will be applied to all the dates in given period."])},
        "specifiedMaxRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If specified, it must be at least equal to minimum values of both length of stay and arrival based length of stay."])},
        "syncedToBookingGeneric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When integration is active and housing is connected to Booking.com room, then this restriction will also be applied to room's rates."])},
        "unspecifiedOptionBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When option is unspecified, then the restriction won't be synced to Booking.com. \nThis means any previously set restriction will stay active there."])},
        "unspecifiedOptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\nIf this option is selected then restriction is not applied."])},
        "unspecifiedOptionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unspecified option"])}
      }
    },
    "restrictionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrictions"])},
    "stayOptionNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unspecified"])},
    "weekslotsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active hours"])}
  },
  "servicePeriodList": {
    "addAnotherPeriodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new period if seasonally the sales article is available at different times of the day."])}
  },
  "servicePeriodPricing": {
    "descriptionHelperInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price name is the name of the price to be dispayed to the end user. Description is optional informative field that is not visible for the end user."])},
    "discountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can choose which discount you want to offer, under what conditions and in what amount. If necessary, you can add several types of discount."])},
    "identificatorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert unique identificator that the system uses to identify the price. It can be price name for example. Don't use spaces or special characters."])},
    "isMinimumPriceWarningAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum price has been applied to current selection."])},
    "minPriceFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum price"])},
    "minPriceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If specified, given value will be enforced as the final total price for the reservation if original total price would be lower otherwise (including discounts). Additional services prices are not affected by minimum price. Their cost will be added after minimum price is enforced."])},
    "packageDealInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The package price does not depend on the number of people. The price is fixed, regardless of the quantity purchased. A maximum group size must be set for the package price, indicating how many people it covers."])},
    "priceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the final price including taxes"])},
    "pricingPropertiesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How sales article price is calculated based on the pricing type:\n"])},
    "ticketCheckboxInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you check this, a ticket with QR-code to validate is generated and sent to client for every purchased item."])}
  },
  "servicePreference": {
    "difficultyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the sales article has a difficulty, set, if not, undefined."])}
  },
  "servicePreferences": {
    "accessibilityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose what is offered."])},
    "amenitiesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the added values, services, options included in the price."])},
    "contractInfo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the contract that defines the terms and conditions between the customer and the service provider."])},
    "contractInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can configure contracts under the Settings - Contracts section."])},
    "difficultyLabels": {
      "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy"])},
      "hard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hard"])},
      "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
      "neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neutral"])}
    },
    "languagesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the languages ​​in which customers are served."])},
    "minReservationTimeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the latest time when it is possible to purchase a ticket. If there is no limit, leave blank."])},
    "targetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the information is important, select the target groups, otherwise leave it blank."])}
  },
  "servicePreview": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on previously entered information, the view that your client can see. If everything important is there, press the publish button at the bottom of the page. A distributed url/link is generated, which you can place in your sales channels (FB, homepage, partners' pages...). Until the first sales transaction, all data can still be changed. After the first sale, the time and price cannot be changed."])}
  },
  "servicePrivateStateFilter": {
    "allLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])},
    "privateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show private"])},
    "publicLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show public"])}
  },
  "serviceProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service provider"])},
  "serviceProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service providers"])},
  "servicePublicURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article public URL:"])},
  "servicePublishButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
  "serviceResource": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you use resource planning, enter it, and if you don't use it, move on."])},
    "newResourceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the necessary resource has not been entered, you can do it here."])}
  },
  "serviceResources": {
    "removeResourceConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove this resource?"])}
  },
  "serviceStateFilter": {
    "showActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show active"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])},
    "showArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show archived"])},
    "showUnpublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show unpublished"])}
  },
  "serviceTabEventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar Titles"])},
  "serviceTabs": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service calendar"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
    "ticketsValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket validation"])}
  },
  "serviceTemplates": {
    "addRelationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose e-mail templates that you want to relate to given sales article"])},
    "addTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create template"])},
    "createNewLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new e-mail template"])},
    "editRelationsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template relations"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related e-mail templates"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See Settings - Mail templates for all templates. Here you can add templates to current service item"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in saving relations"])},
    "saveRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save relations"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relations successfully saved"])},
    "selectTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit template relations"])}
  },
  "serviceTicket": {
    "addAnotherTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another ticket"])},
    "archivedTicketInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This ticket pricing cannot be deleted as there are still active, unused tickets associated with it. The pricing has been archived and is no longer visible to new customers. Please try deleting it again once all tickets have been used."])},
    "confirmArchiveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This ticket pricing data cannot be deleted yet, as there are still active, unused tickets. Do you still want to archive it, preventing new customers from purchasing it?"])},
    "confirmDeleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this ticket? This action cannot be reversed."])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
    "defaultPricesSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
    "defaultQuantitiesSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket amounts"])},
    "defaultSlotsSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeslots"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket successfully deleted"])},
    "isActiveLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "isDurationLimitedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For time period 10:00-15:00 with 1 hour ticket duration, if the ticket is activated at 14:30, will it be valid until 15:00 (end of time period) or 15:30 (end of duration)"])},
    "isDurationLimitedLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket validity expires at the end of the time period"])},
    "isGroupTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is the ticket intended for a group?"])},
    "isGroupTicketInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the ticket is intended for a group (e.g., family or friends), the client will receive the corresponding number of tickets, each with a unique QR code for service access. If this option is disabled, only a single ticket will be generated per order."])},
    "isTimeslotDurationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For time period 10:00-15:00, will the ticket be valid for the whole duration or only 1 hour from activation."])},
    "isTimeslotDurationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket validity matches time periods"])},
    "noPricesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ticket price periods"])},
    "noQuantitiesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum ticket quantity is not specified for any time periods. Timeslots with missing quantities cannot be purchased."])},
    "noSlotsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ticket time slots"])},
    "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "ticketTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket"])},
    "timelineUpdateForm": {
      "addSlotHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press enter to add or open menu from icon"])},
      "noSlotsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No start times have been added, without start times there will be no sales"])},
      "slotsSectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale starting times"])}
    },
    "typeTimeslotLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeslot"])},
    "updateTimelineFormSelectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets and Dates"])},
    "updateTimelineTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update pricing"])},
    "useCustomValuesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom values"])},
    "useDefaultValuesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default values"])}
  },
  "serviceTimeline": {
    "tabTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets & Pricing"])},
    "timelineHeaderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeline"])}
  },
  "serviceType": {
    "eventLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
    "groupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose this option, if you wish to bundle other sales articles to be sold together."])},
    "groupLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article type"])},
    "moneyJarLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Money jar"])},
    "productDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose this option, if you wish to create a product sales article. Products can be sold directly or through other sales articles."])},
    "productLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "salesItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales item"])},
    "ticketDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose this option, if you wish to sell tickets."])},
    "ticketLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket"])}
  },
  "serviceUnits": {
    "g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hour"])},
    "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KG"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L"])},
    "ml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ML"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "otherUnitPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify unit"])},
    "pcs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PCS"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
    "pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])}
  },
  "serviceView": {
    "duplicateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copying is enabled for published sales articles. All sales article data will be duplicated, except for the event dates (in case of event). Duplicated sales article is not published."])},
    "isArchivedLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])},
    "isNotPublishedTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpublished"])},
    "isPrivateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private sales articles cannot be purchased by clients directly and do not show up under related sales articles."])},
    "isPrivateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
    "isPublicLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
    "isPublishedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published sales article is publicly visible."])},
    "isPublishedTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published"])},
    "publishError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to publish sales article."])},
    "publishSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article successfully published."])}
  },
  "service_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
  "service_periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours & Pricing"])},
  "service_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales article"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales articles"])},
  "setDurationManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set duration manually"])},
  "setNewPassword": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set new password"])},
    "submitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid token or password"])},
    "tokenError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid token"])}
  },
  "setUserData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set user data"])},
  "settings": {
    "ServicePublishWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To publish any sales article, you must first fill in the following information"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "tabLabel": {
      "bookingWidget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public View"])},
      "channelList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
      "channelManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel manager"])},
      "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check In Form"])},
      "companyEmails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "compucash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compucash Integration"])},
      "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracts"])},
      "emailTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "googleCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Calendar"])},
      "inviteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invites"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "partnerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Templates"])},
      "trigon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigon"])},
      "userRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Roles"])}
    }
  },
  "settingsCheckIn": {
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create question"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in deleting"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully deleted"])},
    "displayForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display check in form"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional questions"])},
    "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Question"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])}
  },
  "settingsContracts": {
    "archiveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in archiving"])},
    "archiveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully archived"])},
    "archivedSalesArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived sales articles"])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This contract has related order items. When changing the contract data, the original contract will be archived and service relations will be transferred."])},
    "clientConsents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related order items"])},
    "confirmArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to archive?"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete?"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create contract"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracts"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a contract concluded between the Service Provider and the end customer, which may differ depending on the sales items. The service provider can use the standard project prepared by us, edit it if necessary or copy its contract forms here."])},
    "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
    "relatedActiveSalesArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published sales articles"])},
    "relatedPublishedServicesHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published sales articles related to contract"])},
    "saveAsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save as new"])},
    "showArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show archived"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])}
  },
  "settingsLocation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
    "titleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All service provider's locations which can be assigned to sales articles."])}
  },
  "settingsPayments": {
    "getFromAriregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh from e-Business Register"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in the mandatory fields bank name, recipient name and IBAN, and then press the \"save\" button at the bottom right."])},
    "noAriregisterData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])}
  },
  "settingsProfile": {
    "agreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and agree with the web platform's"])},
    "basicInformationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All mandatory fields except km obligee. Enter company data manually or retrieve data from the business register."])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contract"])},
    "contractLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of the contract between the service provider and Touringery"])},
    "descriptionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the description of the company, write a mission, slogan or an important message that applies to all the sales articles offered."])},
    "locationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start typing in the search window. We use Google address search, so enter as you can be found on Google. If you still cannot find the exact address, you can manually enter the county, city, etc."])},
    "socialNetworkAccountsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the existing ones, we show them in the sales article view, next to each sales article."])},
    "vatNumberInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If km is not mandatory, leave the field empty."])}
  },
  "settingsTemplates": {
    "contentPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content preview"])},
    "createInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create e-mail template, schedule sending time, and assign related services"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create template"])},
    "createNewExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create service template"])},
    "createNewHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create housing template"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete template"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demplate was successfully deleted"])},
    "experienceDurationChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of days of the visit"])},
    "experienceEndChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The end date and time"])},
    "experienceStartChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service start date and time"])},
    "forHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For housing"])},
    "forService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For service"])},
    "housingDurationChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of nights of the visit"])},
    "housingEndChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The latest check-out time"])},
    "housingStartChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The check-in date and time range"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail templates"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can set up emails to be sent automatically to the customer"])},
    "relatedServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related services"])},
    "relatedTimings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related timings"])},
    "restrictionCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't send if check-in form is submitted"])},
    "restrictionCheckInInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies a check for sending emails: if client has submitted check-in form, email will not be sent"])},
    "restrictionNoCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't send if check-in form is not submitted"])},
    "restrictionNoCheckInInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applies a check for sending emails: if client has not submitted check-in form, email will not be sent"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save template"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template is saved"])},
    "sendRetroactively": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send retroactively to existent reservations"])},
    "sendRetroactivelyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select, if you wish to send e-mail to already existing reservations. E-mail will be sent, if the timing in e-mail template settings has not passed in relation to the reservation."])},
    "serviceCreateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can configure e-mail templates related to this service article. To edit all e-mail templates, please go to settings view"])},
    "serviceCreateWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bear in mind that if you change e-mail template's content, timings or other settings, the changes apply to all sales articles that are related to this template"])},
    "serviceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the relevant sales for this template"])},
    "timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timing"])},
    "timingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the timing of the letter"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])}
  },
  "settingsUsers": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a user name and email address, set a role and receive an invitation. After confirming the message sent to the email, the new user can start working according to the assigned role."])},
    "tabs": {
      "companyRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company roles"])},
      "usersRignts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users' rights"])}
    },
    "userRolesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define user roles and role permissions and assign each user a role. Users can see and edit only the content that the role enables."])}
  },
  "showAllAmenities": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Show all ", _interpolate(_named("numOfAmenities")), " amenities"])},
  "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
  "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "socialNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social network accounts"])},
  "somethingFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something failed"])},
  "specialRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special requests"])},
  "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special requests"])},
  "specifyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify address details"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "statisticsReport": {
    "companyInformation": {
      "organizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization name"])},
      "organizationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])}
    }
  },
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
  "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcategory"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
  "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor"])},
  "support": {
    "card": {
      "title": {
        "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
        "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send message"])}
      }
    },
    "contactHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9am-5pm"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "facebookGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook group"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "supportExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By submitting this form, you will send a letter to the Touringery site support personnel. The aim of this form is to give feedback and report technical issues about the site."])},
    "tabLabel": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "helpCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help center"])}
    },
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])}
  },
  "switchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch company"])},
  "tabs": {
    "servicePeriodsForEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])}
  },
  "takeMeThere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take me there"])},
  "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task"])},
  "taskAcceptSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task accepted"])},
  "taskColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task color"])},
  "taskColorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task color represents the task card color in calender view. Which improves contrast and makes the calender more readable."])},
  "taskCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task created successfully"])},
  "taskCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task creation failed"])},
  "taskDeleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete task"])},
  "taskDeletedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task deleted successfully"])},
  "taskDurationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task duration is available only when task type is set to open timeslot. Task duration allows to determine the duration less that the start and deadline difference so that the recipient can the task when there is free time to do it."])},
  "taskEndedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task has ended and can not be changed anymore"])},
  "taskGeneratedCreatedSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automated task '", _interpolate(_named("taskTitle")), "' successfully created"])},
  "taskGeneratedCreationError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automated task '", _interpolate(_named("taskTitle")), "' creation failed"])},
  "taskGeneratedDeleteError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automated task '", _interpolate(_named("taskTitle")), "' delete failed"])},
  "taskGeneratedDeleteSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automated task '", _interpolate(_named("taskTitle")), "' successfully deleted"])},
  "taskGeneratedUpdateError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automated task '", _interpolate(_named("taskTitle")), "' update failed"])},
  "taskGeneratedUpdatedSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automated task '", _interpolate(_named("taskTitle")), "' successfully updated"])},
  "taskGenerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automated tasks"])},
  "taskGeneratorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can define tasks that are being auto generated by given variables"])},
  "taskHasEnded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task has ended"])},
  "taskInformationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task information is saved"])},
  "taskInformationSavedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to save task information"])},
  "taskMarkedAsDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task is marked as done"])},
  "taskMarkedAsNotDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task is marked as not done"])},
  "taskPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task period"])},
  "taskRefuseSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task refused"])},
  "taskRefuseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse task?"])},
  "taskReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reminder to recipient"])},
  "taskReminderInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task reminder time reminds the recipient by email for upcoming task"])},
  "taskWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning to supervisor"])},
  "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
  "teamPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team permissions"])},
  "termsOfService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of service"])},
  "textEditor": {
    "enterLinkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter link"])}
  },
  "thisMonthResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This month results"])},
  "ticket": {
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer"])},
    "fetchTicketError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in fetching ticket data"])},
    "invalidScannedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanned code content has unexpected format"])},
    "notValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not validated"])},
    "paidAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid at"])},
    "ticketLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
    "ticketsValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
    "unValidateTicketSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket validation successfully suspended"])},
    "unvalidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch validation status"])},
    "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid from"])},
    "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])},
    "validateTicketError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket validation error"])},
    "validateTicketSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket is successfully validated"])},
    "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated"])},
    "validatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation time"])},
    "validatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated by"])},
    "validationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation"])},
    "validationState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation state"])},
    "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity"])}
  },
  "ticketValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation succeeded"])},
  "ticketValidationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation failed"])},
  "ticketValidity": {
    "disclaimer": {
      "daysFromPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days from purchase"])},
      "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid for"])},
      "validDuringWholeTimeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilet is valid during the whole select timeslot."])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid from"])},
      "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid until"])},
      "validUntilIsLimitedByEndDynamic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ticket is valid for ", _interpolate(_named("duration")), " startin from activation or until timeslot ends, whichever comes first."])},
      "validUntilNotLimitedByEndDynamic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ticket is valid for ", _interpolate(_named("duration")), " starting from activation."])}
    },
    "fixedDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed dates"])},
    "fromUntilDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start and end dates"])},
    "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you define price validity period, this data will be present on the tickets of given time period. You can define either the dates form and until, or set time period starting from the purchase"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price validity period"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undefined"])},
    "periodFromPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period from purchase"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validity period type"])}
  },
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
  "toDetailView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "total": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "payBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to refund"])},
    "payBackYourCompany": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " will pay"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "totalWithDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total with discount"])},
    "totalWithoutVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total without VAT"])}
  },
  "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price"])},
  "totalTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income from the sale of accomodation services"])},
  "travelPurpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travelling purpose"])},
  "travelingToDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travelling to destination"])},
  "travellersCountry": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traveller's citizenship"])},
    "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code"])},
    "housingOvernight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of travellers overnight"])},
    "roomsSold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of travellers"])}
  },
  "travellingPurposesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution of accomodated people according to the purpose of the trip"])},
  "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
  "trigon": {
    "checkBoxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigon integration enabled"])},
    "emailPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigon unique email for invoices"])},
    "helperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By enabling Trigon Integration, a copy of all invoice emails will automatically be sent to Trigon, where the invoices are digitized for easier processing. You can get an email with unique identificator from Trigon where Touringery will send all invoices."])},
    "settingChangeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong with saving the setting"])},
    "settingChangedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting saved successfully"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigon integration"])}
  },
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types"])},
  "unCheckAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uncheck all"])},
  "unauthorized": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are unauthorized to access this view"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])}
  },
  "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
  "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until"])},
  "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "updateCartItemError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart update failed"])},
  "updateCartItemSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart successfully updated"])},
  "updateInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update cart"])},
  "uploadNewAvatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload new avatar"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "userNotAssociatedWithCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is not associated with a company"])},
  "userRoleSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving user role failed"])},
  "userRoleSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User role saved successfully"])},
  "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacation"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
  "vatCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT Category"])},
  "vat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT number"])},
  "visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visitor"])},
  "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visitors"])},
  "warningInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task warning time warns the recipient and supervisor by email for unreacted task"])},
  "week": {
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])}
  },
  "weekSlots": {
    "buttons": {
      "setClear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty"])},
      "setNineToFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9-17 workdays"])},
      "setTenToEight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10-20 every day"])},
      "setWholeWeek ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whole week"])}
    }
  },
  "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When"])},
  "whenAreYouGoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When are you going?"])},
  "where": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where"])},
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work"])},
  "wrongUserNameOrPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong username or password!"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your-profile"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])}
}